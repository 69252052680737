import React from "react";
import { Home, Sliders, User, Users } from "react-feather";
import { PiFlagBannerLight } from "react-icons/pi";
import { FcSurvey } from "react-icons/fc";
import { FaHandHoldingHeart } from "react-icons/fa6";
import { MdOutlineContactPhone } from "react-icons/md";
import { PiNotebookFill } from "react-icons/pi";
import { FcAbout } from "react-icons/fc";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { FcSportsMode } from "react-icons/fc";
import { useLocation, Link } from "react-router-dom";
import { TfiCup } from "react-icons/tfi";
import { FaQuestionCircle } from "react-icons/fa";
import { TbUserX } from "react-icons/tb";
import { MdRuleFolder } from "react-icons/md";
import { CiCoinInsert } from "react-icons/ci";

const Sidebar = ({ open, setOpen }) => {
  const history = useLocation();
  const path = history.pathname;

  return (
    <div
      className={`main-menu menu-fixed menu-light menu-accordion menu-shadow ${
        open ? "close-side-bar" : "open-side-bar"
      }`}
      data-scroll-to-active="true"
    >
      <div className="text-center py-2 px-2 d-flex align-items-start justify-content-between">
        <img
          src="../../../app-assets/images/logo.png"
          alt="Logo"
          style={{
            width: "100px",
          }}
          className="mx-auto"
        />
        <a
          class="nav-link modern-nav-toggle pe-0"
          onClick={() => setOpen(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x d-block d-xl-none text-primary toggle-icon font-medium-4"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </a>
      </div>
      <div className="shadow-bottom" />
      <div
        className="main-menu-content"
        style={{
          maxHeight: "calc(100vh - 90px)", 
          overflowY: "auto", 
          overflowX: "hidden",
        }}
      >
        <ul
          className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          <li
            className={
              "nav-item" + (path === "/Dashboard" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Dashboard">
              <Home />
              <span className="menu-title text-truncate" data-i18n="Dashboards">
                Dashboard
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/User" || path.startsWith("/ViewUser")
                ? " active"
                : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/User">
              <User />
              <span className="menu-title text-truncate" data-i18n="Email">
                Users
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/Survey" ||
              path.startsWith("/ViewSurvey") ||
              path.startsWith("/ViewOptions")
                ? " active"
                : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Survey">
              <FcSurvey />
              <span className="menu-title text-truncate" data-i18n="Categories">
                Survey's
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/SurveyUsers" || path.startsWith("/view")
                ? " active"
                : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/SurveyUsers">
              <Users />
              <span className="menu-title text-truncate" data-i18n="Categories">
                Survey Users
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/Charity" ||
              path.startsWith("/ShowCharity") ||
              path.startsWith("/CharityCreate") ||
              path.startsWith("/DonationUsers")
                ? " active"
                : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Charity">
              <FaHandHoldingHeart />
              <span className="menu-title text-truncate" data-i18n="Categories">
                Charity
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/CharityDonationPoint" ? " active" : " inactive")
            }
          >
            <Link
              className="d-flex align-items-center"
              to="/CharityDonationPoint"
            >
              <CiCoinInsert />
              <span
                className="menu-title text-truncate"
                data-i18n="CharityDonationPoint"
              >
                CharityDonationPoint
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/EditMicroDonation" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/EditMicroDonation">
              <TfiCup />
              <span className="menu-title text-truncate" data-i18n="Contest">
                Micro-Donation
              </span>
            </Link>
          </li>

          <li
            className={
              "nav-item" + (path === "/Contest" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Contest">
              <TfiCup />
              <span className="menu-title text-truncate" data-i18n="Contest">
                Contests
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/ContestRule" ||
              path.startsWith("/AddContestRule") ||
              path.startsWith("/ViewContestRule")
                ? " active"
                : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/ContestRule">
              <MdRuleFolder />
              <span className="menu-title text-truncate" data-i18n="Contest">
                Contest Rule
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" + (path === "/Banner" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Banner">
              <PiFlagBannerLight />
              <span className="menu-title text-truncate" data-i18n="Report">
                Banner
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" + (path === "/Leaderboardbanner" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Leaderboardbanner">
              <PiFlagBannerLight />
              <span className="menu-title text-truncate" data-i18n="Report">
              Leaderboardbanner
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" + (path === "/Report" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Report">
              <TbUserX />
              <span className="menu-title text-truncate" data-i18n="Report">
                Reported Users
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" + (path === "/Preferences" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Preferences">
              <Sliders />
              <span
                className="menu-title text-truncate"
                data-i18n="Preferences"
              >
                Preferences
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" + (path === "/Hobbies" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Hobbies">
              <FcSportsMode />
              <span
                className="menu-title text-truncate"
                data-i18n="Preferences"
              >
                Hobbies
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/getConatct" || path.startsWith("/ViewConatct")
                ? " active"
                : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/getConatct">
              <MdOutlineContactPhone />
              <span
                className="menu-title text-truncate"
                data-i18n="Preferences"
              >
                Contact Us
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/Faq" || path.startsWith("/ViewFaq")
                ? " active"
                : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Faq">
              <FaQuestionCircle />
              <span
                className="menu-title text-truncate"
                data-i18n="Preferences"
              >
                FAQ's
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/Terms&Condition" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/Terms&Condition">
              <PiNotebookFill />
              <span className="menu-title text-truncate" data-i18n="Categories">
                Terms & Condtions
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" +
              (path === "/PrivancyandPolicy" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/PrivancyandPolicy">
              <MdOutlinePrivacyTip />
              <span className="menu-title text-truncate" data-i18n="Categories">
                Privacy Policy
              </span>
            </Link>
          </li>
          <li
            className={
              "nav-item" + (path === "/AboutUs" ? " active" : " inactive")
            }
          >
            <Link className="d-flex align-items-center" to="/AboutUs">
              <FcAbout />
              <span className="menu-title text-truncate" data-i18n="AboutUs">
                About Us
              </span>
            </Link>
          </li>
          <li style={{ height: "300px" }}></li> {/* Empty space */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
