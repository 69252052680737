import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Component/Pages/Login";

import Dashboard from "./Component/Dashboard/Dashboard";
import Adminlayout from "./Component/layout/Adminlayout";
import Profile from "./Component/Pages/Profile";
import Changepassword from "./Component/Pages/Changepassword";
import Terms from "./Component/Contents/Terms";
import AboutUs from "./Component/Contents/AboutUs";
import PrivacyandPolicy from "./Component/Contents/PrivacyandPolicy";
import Preferences from "./Component/Preferences/Preferences";
import AddPreferences from "./Component/Preferences/AddPreferences";
import Hobbies from "./Component/Hobbies/Hobbies";
import AddHobbies from "./Component/Hobbies/AddHobbies";

import User from "./Component/User/User";
import ViewUsers from "./Component/User/ViewUsers";
import Survey from "./Component/Surveys/Survey";
import ViewSurvey from "./Component/Surveys/ViewSurvey";
import AddSurvey from "./Component/Surveys/AddSurvey";
import AddQuestion from "./Component/Surveys/AddQuestion";
import ViewOption from "./Component/Surveys/ViewOption";
import Charity from "./Component/Charity/Charity";
import AddCharity from "./Component/Charity/AddCharity";
import ViewCharity from "./Component/Charity/ViewCharity";
import Contest from "./Component/Contest/Contest";
import AddContest from "./Component/Contest/AddContest";
import ViewContest from "./Component/Contest/ViewContest";
import Faq from "./Component/Faq/Faq";
import ViewFaq from "./Component/Faq/ViewFaq";
import AddFaq from "./Component/Faq/AddFaq";
import DonationUsers from "./Component/Charity/DonationUsers";
import ErrorPage from "./Component/Pages/ErrorPage";

import EditHobbies from "./Component/Hobbies/EditHobbies";
import EditPreferences from "./Component/Preferences/EditPreferences";
import EditFaq from "./Component/Faq/EditFaq";
import EditCharity from "./Component/Charity/EditCharity";
import { useEffect } from "react";
import Surveyusers from "./Component/SurveyUsers/Surveyusers";
import ViewSurveyUser from "./Component/SurveyUsers/ViewSurveyUser";
import ViewAnswer from "./Component/SurveyUsers/ViewAnswer";
import AnswerView from "./Component/SurveyUsers/AnswerView";
import ContactUs from "./Component/ContactUs/ContactUs";
import ContactUsView from "./Component/ContactUs/ContactUsView";
import Banner from "./Component/Banner/Banner";
import AddBanner from "./Component/Banner/AddBanner";
import EditBanner from "./Component/Banner/EditBanner";
import Leaderboardbanner from "./Component/Leaderboardbanner/Leaderboardbanner";
import EditLeaderboardbanner from "./Component/Leaderboardbanner/EditLeaderboardbanner";
import ReportUser from "./Component/ReportUsers/ReportUser";
import ReortedUserview from "./Component/ReportUsers/ReortedUserview";
import ContestRule from "./Component/ContestRules/ContestRule";
import ViewContestRule from "./Component/ContestRules/viewContestRule";
import AddContestRule from "./Component/ContestRules/AddContestRule";
import { CharityDonationPoint } from "./Component/CharityDonationPoint/CharityDonationPoint";
import MicroDonation from "./Component/MicroDonation/MicroDonation";
function App() {
  const local = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  useEffect(() => {
    if (!local) {
      navigate("/");
    }
  }, [local, navigate]);
  return (
    <>
      <Routes>
        <Route index element={<Login />} />
        {local ? (
          <Route path="/" element={<Adminlayout />}>
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Profile" element={<Profile />}></Route>
            <Route path="/ChangePassword" element={<Changepassword />}></Route>
            <Route path="/Terms&Condition" element={<Terms />}></Route>
            <Route path="/AboutUs" element={<AboutUs />}></Route>
            <Route
              path="/PrivancyandPolicy"
              element={<PrivacyandPolicy />}
            ></Route>
            <Route path="/Preferences" element={<Preferences />}></Route>
            <Route path="/AddPreferences" element={<AddPreferences />}></Route>
            <Route
              path="/EditPreferences/:id"
              element={<EditPreferences />}
            ></Route>
            <Route path="/Hobbies" element={<Hobbies />}></Route>
            <Route path="/AddHobbies" element={<AddHobbies />}></Route>
            <Route path="/EditHobbies/:id" element={<EditHobbies />}></Route>
            <Route path="/User" element={<User />}></Route>
            <Route path="/ViewUser/:id" element={<ViewUsers />}></Route>
            <Route path="/Survey" element={<Survey />}></Route>
            <Route path="/ViewSurvey/:id" element={<ViewSurvey />}></Route>
            <Route path="/AddSurvey" element={<AddSurvey />}></Route>

            {/* survey users */}
            <Route path="/SurveyUsers" element={<Surveyusers />}></Route>
            <Route
              path="/viewsSurveyUser/:id"
              element={<ViewSurveyUser />}
            ></Route>
            <Route
              path="/viewAnswer/:surveyId/:userId"
              element={<ViewAnswer />}
            ></Route>
            <Route path="/viewAnswer" element={<AnswerView />}></Route>
            {/* end  */}
            <Route path="/AddQuestion/:id" element={<AddQuestion />}></Route>
            <Route path="/ViewOptions/:id" element={<ViewOption />}></Route>
            <Route path="/Charity" element={<Charity />}></Route>
            <Route path="/CharityCreate" element={<AddCharity />}></Route>
            <Route path="/ShowCharity/:id" element={<ViewCharity />}></Route>
            <Route path="/EditCharity/:id" element={<EditCharity />}></Route>

            <Route
              path="/DonationUsers/:id"
              element={<DonationUsers />}
            ></Route>

            <Route path="/Contest" element={<Contest />}></Route>
            <Route path="/AddContest" element={<AddContest />}></Route>
            <Route path="/ViewContest/:id" element={<ViewContest />}></Route>

            <Route path="/Faq" element={<Faq />}></Route>
            <Route path="/AddFaq" element={<AddFaq />}></Route>
            <Route path="/EditFaq/:id" element={<EditFaq />}></Route>

            <Route path="/ViewFaq/:id" element={<ViewFaq />}></Route>
            <Route path="/getConatct" element={<ContactUs />}></Route>
            <Route path="/ViewConatct/:id" element={<ContactUsView />}></Route>
            <Route path="/Banner" element={<Banner />}></Route>
            <Route path="/AddBanner" element={<AddBanner />}></Route>
            <Route path="/EditBanner/:id" element={<EditBanner />}></Route>
            <Route path="/Leaderboardbanner" element={<Leaderboardbanner />}></Route>
            <Route path="/EditLeaderboardbanner/:id" element={<EditLeaderboardbanner />}></Route>
            <Route path="/Report" element={<ReportUser />}></Route>
            <Route
              path="/ReportedView/:id"
              element={<ReortedUserview />}
            ></Route>
            <Route path="/ContestRule" element={<ContestRule />}></Route>
            <Route
              path="/ViewContestRule/:id"
              element={<ViewContestRule />}
            ></Route>
            <Route path="/AddContestRule" element={<AddContestRule />}></Route>
            <Route
              path="/CharityDonationPoint"
              element={<CharityDonationPoint />}
            ></Route>

            <Route
              path="/EditMicroDonation"
              element={<MicroDonation />}
            ></Route>
          </Route>
        ) : (
          <Route path="*" exact={true} element={<ErrorPage />}></Route>
        )}
        {/* <Route path="*" exact={true} element={<Login />}></Route> */}
      </Routes>

      <ToastContainer />
    </>
  );
}

export default App;
