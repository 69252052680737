import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { http, httpfile } from "../../config/axiosConfig";
import { toast } from "react-toastify";

const AddSurvey = () => {
  const [surveyData, setSurveyData] = useState({
    Title: "",
    Content: "",
    TotalPoint: "",
    image: "",
  });

  const [questions, setQuestions] = useState([
    { question: "", options: ["", "", "", ""] },
  ]);
  const [nameError, setNameError] = useState("");
  const [contentError, setContentError] = useState("");
  const [totalPointError, setTotalPointError] = useState("");
  const [imageError, setImageError] = useState("");
  const navigate = useNavigate();

  const handleSurveyInputChange = (e) => {
    const { name, value } = e.target;
    setSurveyData({ ...surveyData, [name]: value });
  };

  const handleQuestionInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuestions = [...questions];
    updatedQuestions[index][name] = value;
    setQuestions(updatedQuestions);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      const file = e.target.files[0];
      setSurveyData({ ...surveyData, [name]: file });
    } else {
      setSurveyData({ ...surveyData, [name]: value });
    }

    if ((name === "Title" && nameError) || (name === "image" && imageError)) {
      clearError(name);
    }
  };

  const clearError = (name) => {
    if (name === "name") {
      setNameError("");
    } else if (name === "image") {
      setImageError("");
    }
  };

  const handleOptionInputChange = (e, questionIndex, optionIndex) => {
    const { value } = e.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  //Add mmore questions
  const addQuestion = () => {
    setQuestions([...questions, { question: "", options: ["", "", "", ""] }]);
  };

  //Add more options for answers
  const addOptions = () => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) => ({
        ...q,
        options: [...q.options, ""], 
      }))
    );
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    questions.forEach((question, index) => {
      if (question.question.trim() === "") {
        isValid = false;
        toast.error(`Question ${index + 1} is required.`);
      }
    });

    if (!isValid) {
      return;
    }

    try {
      const survey = { ...surveyData, Surveys: JSON.stringify(questions) };
      console.log("survey data =======>", survey);
      const surveyResponse = await httpfile.post(`SurveyCreate`, survey, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (surveyResponse.data) {
        navigate("/Survey");
        toast.success("Survey Added successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="app-content content">
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        <div className="card-header card">
          <h4 className="mb-0 card-title"> Add Survey and Questions</h4>
        </div>
        <form className="" noValidate="novalidate" onSubmit={handleSubmit}>
          <div className="card">
            <div className="modal-body flex-grow-1">
              <div className="mb-1">
                <label>Title</label>
                <input
                  type="text"
                  className={`form-control ${nameError && "is-invalid"}`}
                  placeholder="Title"
                  onChange={handleSurveyInputChange}
                  name="Title"
                />
                {nameError && (
                  <div className="invalid-feedback">{nameError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Content</label>
                <input
                  type="text"
                  className={`form-control ${contentError && "is-invalid"}`}
                  placeholder="Content"
                  onChange={handleSurveyInputChange}
                  name="Content"
                />
                {contentError && (
                  <div className="invalid-feedback">{contentError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Total Points</label>
                <input
                  type="number"
                  className={`form-control ${totalPointError && "is-invalid"}`}
                  placeholder="Total Points"
                  onChange={handleSurveyInputChange}
                  name="TotalPoint"
                />
                {totalPointError && (
                  <div className="invalid-feedback">{totalPointError}</div>
                )}
              </div>
              <label>Image</label>
              <div className="col-12 d-flex mt-1 px-0 mb-1">
                <input
                  onChange={handleInputChange}
                  className="form-control"
                  type="file"
                  id="change-picture"
                  hidden=""
                  accept="image/png, image/jpeg, image/jpg"
                  name="image"
                  style={{ padding: "0.200rem" }}
                />
              </div>
              {imageError && <p className="text-danger">{imageError}</p>}
            </div>
          </div>
          <div className="card-header card">
            <h4 className="mb-0 card-title"> Add Questions</h4>
          </div>
          <div className="card">
            <div className="modal-body flex-grow-1">
              {questions.map((question, index) => (
                <div key={index} className="mb-3">
                  <label>Question {index + 1}</label>
                  <input
                    type="text"
                    className="form-control mb-1"
                    placeholder="Question"
                    value={question.question}
                    onChange={(e) => handleQuestionInputChange(e, index)}
                    name="question"
                  />
                  {question.options.map((option, optionIndex) => (
                    <input
                      key={optionIndex}
                      type="text"
                      className="form-control mb-1"
                      placeholder={`Option ${optionIndex + 1}`}
                      value={option}
                      onChange={(e) =>
                        handleOptionInputChange(e, index, optionIndex)
                      }
                    />
                  ))}
                </div>
              ))}
              <button
                type="submit"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                onClick={addQuestion}
              >
                Add More Question
              </button>
              <button
                type="button"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                onClick={addOptions}
              >
                Add More Options
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSurvey;
