import React, { useState, useEffect } from "react";
import axios from "axios";
import { Eye } from "react-feather";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "../Surveys/Survey.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import ViewOption from "./ViewOption";
import { http, httpfile } from "../../config/axiosConfig";
import Swal from "sweetalert2";

const ViewSurvey = () => {
  const [data, setData] = useState();

  const [titleError, setTitleError] = useState("");
  const [contentError, setContentError] = useState(""); // show all users in table
  const Id = useParams().id;
  const navigate = useNavigate();

  const getoneSurveys = async () => {
    await httpfile
      .get(`getoneSurvey`, {
        params: {
          _id: Id,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.body);
        }
      })
      .catch((error) => {
        console.log(error, ".........not get");
      });
  };

  useEffect(() => {
    getoneSurveys();
  }, []);
  const nextPageData = data?.Surveys;

  const DeleteSurveysQuestion = async (id) => {
    const params = {
      id: id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete this Question!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete ",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await http.post(`QuestionDelete`, params);
          if (res.data.code === 200) {
            getoneSurveys();
            
          }
        } catch (error) {
          console.error("error message ", error);
          toast.error("Error deleting survey");
        }
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
  }

  const validateInput = () => {
    let valid = true;
    if (!data.title) {
      setTitleError("Title is required");
      valid = false;
    }
    if (!data.Content) {
      setContentError("Content is required");
      valid = false;
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await httpfile
      .put("editSurvey", data)
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          toast.success(" Edit successfully");
            navigate("/Survey");

             getoneSurveys();
         
        }
      })
      .catch((err) => {
        console.log(err, "========== from .Faq");
      });
  };

  return (
    <>
      <div>
        <div className="app-content content ">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper container-xxl p-0">
            <div className="content-header row">
              <div className="content-header-left col-md-9 col-12 mb-2"></div>
            </div>
            <div className="content-body">
              <section id="basic-datatable">
                <div className="row">
                  <div className="col-12">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <img
                            className="img-fluid rounded"
                            style={{ aspectRatio: "10/7", objectFit: "cover" }}
                            src={`${process.env.REACT_APP_BASEAPI_URL}images/Charity/${data?.files}`}
                            alt="img"
                          />
                        </div>
                        <div className="col-12 d-flex mt-1 px-0">
                          <input
                            onChange={handleInputChange}
                            className="form-control mb-1"
                            type="file"
                            id="change-picture"
                            hidden=""
                            accept="image/png, image/jpeg, image/jpg"
                            name="image"
                          />
                        </div>
                        <div className="col-12"></div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Title</label>
                            <input
                              className="form-control"
                              type="text"
                              name="Title"
                              id=""
                              value={data?.Title}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-12"></div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Points</label>
                            <input
                              className="form-control"
                              type="nummber"
                              name="TotalPoint"
                              id=""
                              value={data?.TotalPoint}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="">Content</label>
                            <textarea
                              name="Content"
                              id=""
                              onChange={handleInputChange}
                              className="form-control"
                              rows="3"
                              value={data?.Content || ""}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Save As
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="card-header border-bottom p-1">
                          <div className="head-label">
                            <h6 className="mb-0">Survey Questions</h6>
                          </div>
                          {/* <Link to={`/AddQuestion/${Id}`}>
                            <button
                              type="submit"
                              className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                            >
                              Add Question
                            </button>
                          </Link> */}
                        </div>
                        <div className="pagination-container"></div>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">S.No.</th>
                              <th scope="col">Questions</th>
                              {/* <th scope="col">Description</th>   */}
                              <th scope="col">Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.Surveys?.map((one, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{one?.SurveyName}</td>
                                {/* <td>{one?.Description}</td> */}
                                <td>
                                  <Link
                                    className="btn btn-outline-primary btn-sm"
                                    to={`/ViewOptions/${one._id}`}
                                    state={{
                                      data: nextPageData.find(
                                        (e) => e._id === one._id
                                      ),
                                      ID: Id,
                                    }}
                                  >
                                    <Eye />
                                  </Link>
                                  <Link
                                    className="btn btn-outline-danger btn-sm mx-1"
                                    onClick={() =>
                                      DeleteSurveysQuestion(one._id)
                                    }
                                  >
                                    <MdDelete />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSurvey;
